import React, { Component } from 'react';
import {addSetting, updateSetting, deleteSetting} from '../../API/setting';
import MaterialTable from "material-table";
import {Collapse,IconButton} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

export class StatusList extends Component {
    static displayName = StatusList.name;

    constructor(props) {
      super(props);

      this.state = {
        data:null,
        dialog: {open:false, text:''}
      };

      this.onDialogClose = this.onDialogClose.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
      if(this.props.data !== undefined && prevProps.data !== this.props.data){
        this.setState({data:this.props.data});
      }
    }

    renderTable(data, emailFormat) {
      let {dialog} = this.state;

      return (
        <div>
          <Collapse in={dialog.open} className="p-2">
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.onDialogClose}
                  >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {dialog.text}
            </Alert>
          </Collapse>
          <MaterialTable 
            columns={[
              { title: "Id", field: "id", type:"numeric", editable:"never", hidden:true, cellStyle:{width:"25px"} },
              { title: "Status", field: "value" },
              { title: "Emailmall", field: "emailFormatId", initialEditValue:'1', emptyValue:'', lookup:emailFormat },
              { title: "Skicka mail", field: "emailSend", type: "boolean", cellStyle:{width:"140px"} },
              { title: "Standard", field: "default", type: "boolean", cellStyle:{width:"25px"} },
              { title: "Aktiverad", field: "enabled", type: "boolean", cellStyle:{width:"25px"} }
            ]}
            data={data}
            options={{
              addRowPosition: 'first',
              draggable: false,
              filtering: false,
              selection: false,
              search: false,
              paging: false
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Ingen ansökningsstatus finns',
                addTooltip: 'Lägg till ansökningsstatus',
                deleteTooltip: 'Ta bort',
                editTooltip: 'Ändra',
                editRow: {
                  deleteText: 'Vill ni verkligen ta bort den här ansökningsstatusen?',
                  cancelTooltip: 'Avbryt',
                  saveTooltip: 'Spara'
                }
              },
              header: {
                actions: ''
              }
            }}
            editable={{
              onRowAdd:this.onRowAdd.bind(this), 
              onRowUpdate:this.onRowUpdate.bind(this), 
              onRowDelete:this.onRowDelete.bind(this)
            }}
            title="Statuslista" />
        </div>
      );
    }

    render() {
      let {data} = this.state;
      let {emailFormat} = this.props;
      let content = data !== null && emailFormat !== null
        ? this.renderTable(data, emailFormat)
        : "";

      return (
        <div>
          {content}
        </div>
      );
    }

    onDialogClose(){
      let {dialog} = this.state;
      dialog.open = false;
      this.setState({dialog:dialog});
    }

    onRowAdd(newData, event){
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          {
            if(!newData.hasOwnProperty('value') || newData.value === ''){
              let {dialog} = this.state;
              dialog.open = true;
              dialog.text = 'Kan inte lägga till rad utan statusnamn.';
              this.setState({dialog:dialog});
              reject();
              return;
            }
            
            const data = this.state.data;
            newData.settingType = this.props.settingTypeId;
            const response = this.apiAddNew(newData);
            newData = await response;
            data.push(newData);
            this.setState({ data });
            this.props.onChange(data);
          }
          resolve();
        }, 1000)
      })
    }
      
    onRowUpdate(newData, oldData) {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          {
            const data = this.state.data;
            const index = data.indexOf(oldData);
            await updateSetting(oldData.id, newData);
            data[index] = newData;
            this.setState({ data }, () => resolve());
            this.props.onChange(data);
          }
          resolve()
        }, 1000)
      })
    }
      
    onRowDelete(oldData){
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          {
            let data = this.state.data;
            const index = data.indexOf(oldData);
            try {
              await deleteSetting(data[index].id);
            } catch {
              let {dialog} = this.state;
              dialog.open = true;
              dialog.text = 'Kan inte ta bort status.';
              this.setState({dialog:dialog});
              reject();
              return;
            }
            
            data.splice(index, 1);
            this.setState({ data }, () => resolve());
            this.props.onChange(data);
          }
          resolve()
        }, 1000)
      })
    }

    async apiAddNew(data){
      let realData = {
        'settingType': data.settingType,
        'value': data.value,
        'emailSend': data.hasOwnProperty('emailSend')? data.enabled : false,
        'enabled': data.hasOwnProperty('enabled')? data.enabled : false
      };

      const response = await addSetting(realData);
      return await response.json();
    }
}
