import React, { Component } from 'react';
import {getSettingById, getSettingTypes} from '../../API/setting';
import {getEmailFormats} from '../../API/email';
import { Grid } from '@material-ui/core';
import {StatusList} from '../Settings/StatusList';
import {GradeList} from '../Settings/GradeList';
import {EmailFormatList} from '../Settings/EmailFormatList';
import {makeLookupFormat} from '../../Helper';

export class Setting extends Component {
    static displayName = Setting.name;

    constructor(props) {
      super(props);
      this.state = {
        data: {},
        emailFormat: null,
        lookupFormat: {}
      }

      this.populateSettings = this.populateSettings.bind(this);
      this.populateEmailFormats = this.populateEmailFormats.bind(this);
      this.populateData = this.populateData.bind(this);
    }

    componentDidMount(){
      this.populateData();
    }

    render() {
      const {data, emailFormat, lookupFormat} = this.state;

      return (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EmailFormatList emailFormat={emailFormat} onChange={this.onEmailFormatChange.bind(this)} />
          </Grid>
          <Grid item xs={12}>
            <StatusList data={data.Status} emailFormat={lookupFormat} settingTypeId={2} onChange={this.onStatusChange.bind(this)} />
          </Grid>
          <Grid item xs={12}>
            <GradeList data={data.Grade} settingTypeId={1} onChange={this.onGradeChange.bind(this)} />
          </Grid>
        </Grid>
      );
    }

    onEmailFormatChange(data, major){
      if(major){
        this.setState({emailFormat:data, lookupFormat:makeLookupFormat(data, 'name')});
      }
    }

    onStatusChange(data){

    }

    onGradeChange(data){
      
    }

    async populateData(){
      const emailFormat = await this.populateEmailFormats();
      const settings = await this.populateSettings();

      this.setState({lookupFormat:makeLookupFormat(emailFormat, 'name'), emailFormat:emailFormat, data:settings});
    }

    async populateEmailFormats(){
      const response = await getEmailFormats();
      return await response.json();
    }

    async populateSettings(){
      const response = await getSettingTypes();
      const data = await response.json();
      let result = {};
      for(const type of data){
        result[type.name] = await this.populateSetting(type.id);
      }

      return result;
    }

    async populateSetting(id){
      const response = await getSettingById(id);
      return await response.json();
    }
}
