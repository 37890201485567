import React, { Component } from 'react';
import InputMask from "react-input-mask";
import '../../custom.css';

//const numberRegex = /07[0|3|6]-?[0-9]{3}\s[0-9]{2}\s[0-9]{2}/i;

const digit = /[0-9]/;
const regexArray = ["0", "7", digit, "-", digit, digit, digit, " ", digit, digit, " ", digit, digit];

export class Parent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputData: {}
    }
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount(){
  }

  render() {
    let {language} = this.props;

    return (
      <div className={`form-row slideDown`} key={this.props.index}>
        <div className="form-group col-md-6">
          <label htmlFor="firstname">{language.firstName}</label>
          <input className="form-control" size="small" variant="outlined" id="FirstName" onChange={this.onChange} placeholder={language.firstName} required/>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="lastname">{language.lastName}</label>
          <input className="form-control" size="small" variant="outlined" id="LastName" onChange={this.onChange} placeholder={language.lastName} required/>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="adress">{language.address}</label>
          <input className="form-control" size="small" variant="outlined" id="Street" onChange={this.onChange} placeholder={language.address} required/>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="phone">{language.phoneNumber}</label>
          <InputMask mask={regexArray} maskPlaceholder="X" alwaysShowMask={false} onChange={this.onChange} id="PhoneNumber">
            <input className="form-control" type="tel" size="small" variant="outlined" pattern="07[0|2|3|6]-?[0-9]{3}\s[0-9]{2}\s[0-9]{2}" />
          </InputMask>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="email">{language.email}</label>
          <input className="form-control" size="small" type="email" variant="outlined" id="Email" onChange={this.onChange} placeholder={language.email} required/>
        </div>
      </div>
    );
  }

  onChange(event){
    console.log(event);
    let {inputData} = this.state;
    let target = event.target;
    let type = target.type;

    switch(type){
      case 'text':
      case 'phone':
      case 'email':
        inputData[target.id] = target.value;
        break;
      case 'tel':
        if(target.value !== '07X-XXX XX XX'){
          inputData[target.id] = target.value;
        }
        break;
      default:
        console.log(type);
        break;
    }

    this.props.onChange(inputData, this.props.index);
    this.setState({inputData:inputData});
  }
}