import React, { Component } from 'react';
import { Parent } from '../Form/Parent';
import '../../custom.css'

export class ParentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputData: [],
      additionalParents: false,
      parents: [],
      secondparent: false
    }

    this.onChange = this.onChange.bind(this);
  }

  render() {
    let {language} = this.props;

    return (
      <React.Fragment>
        <div className="form-group col-md-6">
          <h5>{language.headerParent}</h5>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <div className="form-check form-check-inline">
              <input className="checkbox" type="checkbox" id="parent" value="parent" onChange={this.checkParent.bind(this)} />
              <label htmlFor="parent" className="form-check-label">{language.addParent}</label>
            </div>
          </div>
        </div>
        <Parent key={0} index={0} language={language} onChange={this.onChange} />
        {this.state.additionalParents ?
          this.state.parents.map((item) => {
            return <Parent key={item} index={item} language={language} onChange={this.onChange} />
          }) : ""
        }
        <hr />
      </React.Fragment>
    );
  }

  checkParent(event) {
    let { parents, additionalParents } = this.state;
    console.log(event.target.checked);
    event.target.scrollIntoView();

    if (event.target.checked) {
      parents = parents.concat(this.state.parents.length + 1);
    } else {
      parents.splice(parents.length - 1, 1);
    }

    this.setState({
      additionalParents: !additionalParents,
      parents: parents
    });
  }

  additionalParent(event) {
    this.setState({ parents: this.state.parents.concat(this.state.parents.length + 1) });
    event.preventDefault();
  }

  onChange(data, index) {
    let {inputData} = this.state;

    if(inputData.length < index){
      for(let i = inputData.length; i < index; i++){
        inputData.push(null);
      }
    }
      
    inputData[index] = data;

    this.setState({inputData:inputData});
    console.log(inputData);
    this.props.onChange(inputData, this.props.dataId);
  }
}