import constants from '../API/constants';
import {Get, GetById, Add, Update, Delete} from '../API/api';

export async function getApplicants() {
  return Get(constants.applicant);
}

export async function getApplicantById(id){
  return GetById(constants.applicant, id);
}

export async function addApplicant(data){
  return Add(constants.applicant, data, false);
}

export async function updateApplicant(id, data){
  return Update(constants.applicant, id, data);
}

export async function deleteApplicant(id){
  return Delete(constants.applicant, id);
}

export async function addParent(data){
  return Add(constants.parent, data, false);
}

export async function updateParent(id, data){
  return Update(constants.parent, id, data);
}

export async function deleteParent(id){
  return Delete(constants.parent, id);
}

export async function addSibling(data){
  return Add(constants.sibling, data, false);
}

export async function updateSibling(id, data){
  return Update(constants.sibling, id, data);
}

export async function deleteSibling(id){
  return Delete(constants.sibling, id);
}