const Constants = {
  'setting_type': 'api/settingtype',
  'setting': 'api/setting',
  'email_format': 'api/EmailFormat',
  'applicant': 'applicants',
  'sibling': 'api/siblings',
  'parent': 'api/parents',
  'mail': 'api/mail',
  'note': 'api/ApplicantHistories',
  'admin': 'api/Role'
}

export default Constants;