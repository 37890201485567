import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { ApplicationList } from './components/Pages/ApplicationList';
import { AnsokningForm } from './components/Pages/Form';
import { Setting } from './components/Pages/Setting';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { LinearProgress } from '@material-ui/core';

//import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
    this.state = {isLoading:false};
  }

  MyApplicationList = (props) => {
    return (
      <ApplicationList isLoading={this.fetchDataIsLoading.bind(this)} {...props} />
    );
  }

  fetchDataIsLoading(loading){
    this.setState({isLoading:loading});
  }

  render () {
    let loading = this.state.isLoading
      ? <LinearProgress variant="query" />
      : null;

    return (
      <div>
        {loading}
        <Switch>
            <Route exact path='/' component={AnsokningForm} />
            <Route>
                <Layout>
                    <AuthorizeRoute path='/applications' component={this.MyApplicationList} />
                    <AuthorizeRoute path='/settings' component={Setting} />
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                </Layout>
            </Route>
        </Switch>
      </div>
    );
  }
}
