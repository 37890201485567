import constants from '../API/constants';
import {Get, Add, Update, Delete} from '../API/api';

export async function getEmailFormats(){
  return Get(constants.email_format);
}

export async function addEmailFormat(data){
  return Add(constants.email_format, data, true);
}

export async function updateEmailFormat(id, data){
  return Update(constants.email_format, id, data);
}

export async function deleteEmailFormat(id){
  return Delete(constants.email_format, id);
}

export const EMAIL_BACKUP_ADDR = "mathias.svensson-karlsson@britt.se";

/*export async function sendEmail(data){
  return Add(constants.mail, data, true);
}*/