import React, { Component } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from "reactstrap";
import { Link } from "react-router-dom";
import { LoginMenu } from "./api-authorization/LoginMenu";
import { isAdmin } from '../API/setting';
import "./NavMenu.css";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      admin: false
    };
  }

  componentDidMount(){
    this.init();
  }

  async init(){
    let response = await isAdmin();
    if(response === null){
      return;
    }
    
    let result = await response.json();
    this.setState({admin:result});
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
          light
        >
          {/* <img src="/British-Junior.png" alt="British Junior" /> */}
          <Container>
            <NavbarBrand tag={Link} to="/applications">
              Ansökningshantering
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse
              className="d-sm-inline-flex flex-sm-row-reverse"
              isOpen={!this.state.collapsed}
              navbar
            >
              <ul className="navbar-nav flex-grow">
                {/*<NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">
                    Home
                  </NavLink>
                </NavItem>*/}
                {this.state.admin &&
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/settings">
                      Inställningar
                    </NavLink>
                  </NavItem>
                }
                <LoginMenu></LoginMenu>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
