import {addNote} from './API/note';

export function makeLookupFormat(data, col){
  let lookupFormat = {};
  data.forEach((item) => {
    lookupFormat[item.id] = item[col];
  });

  return lookupFormat;
};

export function parseSSN(ssn){
  if(ssn == null || ssn === "" || ssn.length < 10)
    return "Unknown";

  let num = +ssn.slice(-2);
  return (num % 2 === 0)? 'Girl' : 'Boy';
}

const logEntryType = {
  gender: 'Kön',
  firstName: 'Förnamn',
  lastName: 'Efternamn',
  ssn: 'Personnr',
  passedYears: 'Utgångsår',
  present: 'Nuvarande',
  grade: 'Årskurs',
  startYear: 'Startår',
  language: 'Språk',
  afternoonSchool: 'Afternoon School',
  status: 'Status',
  removeParent: 'Förälder borttagen',
  addParent: 'Förälder tillagd',
  updateParent: 'Förälder uppdaterad',
  removeSibling: 'Syskon borttaget',
  addSibling: 'Syskon tillagt',
  updateSibling: 'Syskon uppdaterat'
};

function convertValueToReadable(val){
  switch(val){
    case "Boy":
      return "pojke";
    case "Girl":
      return "flicka";
    case undefined:
    case null:
      return "tom";
    case true:
      return "ja";
    case false:
      return "nej";
    default:
      return val;
  }
}

export async function addAppLogEntry(type, applicantId, oldVal, newVal){
    let newData = {};

    oldVal = convertValueToReadable(oldVal);
    newVal = convertValueToReadable(newVal);

    switch(type){
      case 'addSibling':
      case 'updateSibling':
      case 'removeSibling':
      case 'addParent':
      case 'updateParent':
      case 'removeParent':
        newData.note = `${logEntryType[type]}.`;
        break;
      default:
        newData.note = `${logEntryType[type]} ändrat från ${oldVal} till ${newVal}.`;
        break;
    }

    newData.created = new Date();
    newData.applicantId = applicantId;
    try {
      const response = await addNote(newData);
      let responseData = await response.json();
      console.log(responseData);
    } catch {
      
    }
}