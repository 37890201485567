import React, { Component, renderToStaticMarkup } from 'react';
//import {Editor, EditorState, ContentState, RichUtils} from 'draft-js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

export class EmailFormatEditor extends Component {
    static displayName = EmailFormatEditor.name;

    constructor(props) {
      super(props);
      this.state = {
        text: this.props.value
      };

      this.handleChange = this.handleChange.bind(this);
    }

    
    render() {
      return (
        <ReactQuill value={this.state.text}
          onChange={this.handleChange} 
          modules={modules}
          formats={formats}
        />
      );
    }

    handleChange(value, delta, source) {
      this.setState({ text: value });
      //console.log(value);
      //console.log(this.stripHtml(value));
      this.props.onChange(value);
    }
}
