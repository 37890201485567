import React, { Component } from 'react';
import SiblingCard from './SiblingCard';
import { Grid } from '@material-ui/core';

class SiblingView extends Component {
    render() {
      const { classes, data } = this.props;

      //let newCard = <SiblingCard data={null} />

      return (
        <Grid item xs={6}>
          <Grid container className={classes.root} spacing={1}>
            {data.map((sibling, index) => {
              return (
                <Grid item key={index} xs={6}>
                  <SiblingCard data={sibling} onDelete={this.props.onDelete} onUpdate={this.props.onUpdate} />
                </Grid>
              );
            })}
            <Grid item key={-1} xs={6}>
              <SiblingCard data={null} onAdd={this.props.onAdd} applicantId={this.props.applicantId} />
            </Grid>
          </Grid>
        </Grid>
      );
    }
}

export default SiblingView;