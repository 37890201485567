import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ParentView from './Parents/ParentView';
import SiblingView from './Siblings/SiblingView';
import NoteTable from './Notes/NoteTable';
import {addAppLogEntry} from '../../Helper';
import {Paper, Divider, Card, CardContent, Typography, Grid, Box, LinearProgress} from '@material-ui/core';
import {getApplicantById, addParent, updateParent, deleteParent, addSibling, updateSibling, deleteSibling } from '../../API/applicant';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: '100%'
  },
  paperBody: {
    paddingLeft: theme.spacing(1)
  },
  control: {
    padding: theme.spacing(2),
  }
});

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {data:[], loading:true};
    this.onAddParent = this.onAddParent.bind(this);
    this.onAddSibling = this.onAddSibling.bind(this);
    this.onRemoveParent = this.onRemoveParent.bind(this);
    this.onRemoveSibling = this.onRemoveSibling.bind(this);
    this.onUpdateParent = this.onUpdateParent.bind(this);
    this.onUpdateSibling = this.onUpdateSibling.bind(this);
  }

  componentDidMount() {
    this.populateData();
  }

  renderTable(data, classes) {
    if(data.parent == null)
      return;

    let history = this.state.data.applicantHistory;

    return (
        <Box p={2}>
            <Grid container spacing={3}>
                <ParentView data={this.state.data.parent} applicantId={data.id} classes={classes} onAdd={this.onAddParent} onDelete={this.onRemoveParent} onUpdate={this.onUpdateParent} />
                <SiblingView data={this.state.data.sibling} applicantId={data.id} classes={classes} onAdd={this.onAddSibling} onDelete={this.onRemoveSibling} onUpdate={this.onUpdateSibling} />
                {this.state.data.comment !== null && this.state.data.comment !== '' &&
                  <Grid item xs={3}>
                    <Card className={classes.paper}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          Kommentar ansökande
                        </Typography>
                        <Typography variant="body2" className={classes.paperBody} component="p">
                          {this.state.data.comment}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                }
                <Grid item xs={((this.state.data.comment === null || this.state.data.comment === '')? 12 : 9)}>
                    <Grid container className={classes.root} spacing={1}>
                        <Grid item key={0} xs={12}>
                            <NoteTable data={history} applicantId={data.id} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box> 
    );
  }

  render(){
    const { classes } = this.props;
    let contents = this.state.loading
      ? <LinearProgress variant="query" />
      : this.renderTable(this.state.data, classes);

    return (
      <div>{contents}</div>
    );
  }

  onRowAdd(newData){
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        {
          const data = this.state.data;
          data.push(newData);
          this.setState({ data }, () => resolve());
        }
        resolve();
      }, 1000)
    })
  }
    
  onRowUpdate(newData, oldData) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        {
          const data = this.state.data;
          const index = data.indexOf(oldData);
          data[index] = newData;
          this.setState({ data }, () => resolve());
        }
        resolve()
      }, 1000)
    })
  }
    
  onRowDelete(oldData){
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        {
          let data = this.state.data;
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          this.setState({ data }, () => resolve());
        }
        resolve()
      }, 1000)
    })
  }

  async populateData(){
    const response = await getApplicantById(this.props.data.id);

    if(!response.ok){
      throw new Error("getApplicantById");
    }

    const data = await response.json();
    console.log(data);
    this.setState({ data:data, loading:false });
  }

  async onAddParent(applicantId){
    let {data} = this.state;
    let newData = {
      applicantId:applicantId,
      firstName:'',
      lastName:'',
      email:'',
      street:'',
      postcode:'',
      mobileNumber:'',
      workNumber:'',
      phoneNumber:''
    };

    try {
      const response = await addParent(newData);
      const responseData = await response.json();
      newData.id = responseData.id;
      await addAppLogEntry("addParent", applicantId, null, newData);
    } catch {
      return;
    }
  
    data.parent.push(newData);

    this.setState({
      data:data
    });
  }

  async onAddSibling(applicantId){
    let {data} = this.state;
    let newData = {
      applicantId:applicantId,
      firstName:'',
      lastName:'',
      ssn:''
    }

    try {
      const response = await addSibling(newData);
      const responseData = await response.json();
      newData.id = responseData.id;
      await addAppLogEntry("addSibling", applicantId, null, newData);
    } catch {
      return;
    }
  
    data.sibling.push(newData);

    this.setState({
      data:data
    });
  }

  async onUpdateParent(parent){
    const {data} = this.state;
    const index = data.parent.indexOf(parent);
    await updateParent(parent.id, parent);
    await addAppLogEntry("updateParent", parent.applicantId, null, null);
    data.parent[index] = parent;
    this.setState({data:data});
  }

  async onUpdateSibling(sibling){
    const {data} = this.state;
    const index = data.sibling.indexOf(sibling);
    await updateSibling(sibling.id, sibling);
    await addAppLogEntry("updateSibling", sibling.applicantId, null, null);
    data.sibling[index] = sibling;
    this.setState({data:data});
  }

  async onRemoveParent(parent){
    let {data} = this.state;
    const index = data.parent.indexOf(parent);
    try {
      await deleteParent(data.parent[index].id);
      await addAppLogEntry("removeParent", parent.applicantId, null, null);
    } catch {
      return;
    }
    data.parent.splice(index, 1);
    this.setState({data:data});
  }

  async onRemoveSibling(sibling){
    let {data} = this.state;
    const index = data.sibling.indexOf(sibling);

    try {
      await deleteSibling(data.sibling[index].id);
      await addAppLogEntry("removeSibling", sibling.applicantId, null, null);
    } catch {
      return;
    }
    data.sibling.splice(index, 1);
    this.setState({data:data});
  }
}

export default withStyles(styles)(Detail);