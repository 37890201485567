import React, { Component } from 'react';
import {Sibling} from '../Form/Sibling';
import '../../custom.css'

export class SiblingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputData: [],
      siblings: false,
      sibs: []
    }

    this.onChange = this.onChange.bind(this);
  }

  render() {
    let {language} = this.props;

    return (
      <React.Fragment>
        <div className="form-row">
          <div className="form-group col-md-6">
            <div className="form-check form-check-inline">
              <input className="checkbox" type="checkbox" id="sibling" value="sibling" onChange={() => this.setState({ siblings: !this.state.siblings })} />
              <label htmlFor="sibling" className="form-check-label">{language.checkSibling}</label>
            </div>
          </div>
          <div className="form-group col-md-6">
            {this.state.siblings ? <a href="#" onClick={this.checkSibling.bind(this)}>{language.addSibling}</a> : ""}
          </div>
        </div>
        {this.state.siblings &&
          <div>
            <h5>{language.headerSibling}</h5>
            <Sibling key={0} index={0} language={language} onChange={this.onChange} />
            {this.state.sibs.map((item) => {
              return <Sibling key={item} index={item} language={language} onChange={this.onChange} />
            })}
          </div>
        }
        <hr />
      </React.Fragment>
    );
  }

  checkSibling(event){
    this.setState({ sibs: this.state.sibs.concat(this.state.sibs.length + 1) });
    event.preventDefault();
  }

  onChange(data, index) {
    let {inputData} = this.state;

    if(inputData.length < index){
      for(let i = inputData.length; i < index; i++){
        inputData.push(null);
      }
    }
      
    inputData[index] = data;

    this.setState({inputData:inputData});
    this.props.onChange(inputData, this.props.dataId);
  }
}