import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import MaterialTable from "material-table";
import {deleteNote, addNote} from '../../../API/note';
//import "../../../custom.css";

const styles = theme => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
        expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: green[500],
    }
});

const colWidth = {
    width: '200px'
};

class NoteTable extends Component {
  constructor(props) {
    super(props);
    this.state = { data: this.props.data };
  }

  componentDidMount() {

  }

  render(){
    const { data } = this.state;
    return (
        <MaterialTable columns={[
            { 
              title: "Datum", 
              field: "created", 
              type:"datetime", 
              cellStyle:colWidth, 
              editable: 'never',
              defaultSort: 'desc',
              render: rowData => <div>{(rowData == null || !rowData.hasOwnProperty('created'))? "" : new Intl.DateTimeFormat("sv-SE", {year:"numeric", month:"numeric", day:"numeric", hour:"2-digit", minute:"2-digit"}).format(new Date(rowData.created))}</div>
            },
            { title: "Notering", field: "note" }
          ]}
          options={{
              addRowPosition: 'first',
              search: false,
              draggable: false,
              paging: false
          }}
          data={data}
          editable={{
            onRowAdd:this.onRowAdd.bind(this), 
            onRowDelete:this.onRowDelete.bind(this)
          }}
          localization={{
            body: {
              addTooltip: 'Lägg till notering',
              deleteTooltip: 'Ta bort',
              emptyDataSourceMessage: 'Inga noteringsuppgifter finns',
              editTooltip: 'Ändra',
              editRow: {
                deleteText: 'Vill ni verkligen ta bort den här noteringen?',
                cancelTooltip: 'Avbryt',
                saveTooltip: 'Spara'
              }
            },
            header: {
              actions: ''
            },
            pagination: {
              labelDisplayedRows: '{from}-{to} av {count}',
              labelRowsSelect: 'rader',
              labelRowsPerPage: 'Rader per sida:',
              firstAriaLabel: 'Första sidan',
              firstTooltip: 'Första sidan',
              previousAriaLabel: 'Föregående sida',
              previousTooltip: 'Föregående sida',
              nextAriaLabel: 'Nästa sida',
              nextTooltip: 'Nästa sida',
              lastAriaLabel: 'Sista sidan',
              lastTooltip: 'Sista sidan'
            },
            toolbar: {
              searchTooltip: 'Sök',
              searchPlaceholder: 'Sök'
            }
          }}
          title="Noteringar" />
    );
  }

    onRowAdd(newData){
        return new Promise((resolve, reject) => {
        setTimeout(async () => {
          {
            console.log(newData);
            const data = this.state.data;
            newData.created = new Date();
            newData.applicantId = this.props.applicantId;
            try {
              const response = await addNote(newData);
              newData = await response.json();
            } catch {
              reject();
              return;
            }
            data.push(newData);
            this.setState({ data }, () => resolve());
            }
            resolve();
          }, 1000)
        })
    }
    
    onRowDelete(oldData){
        return new Promise((resolve, reject) => {
          setTimeout(async () => {
          {
              let data = this.state.data;
              const index = data.indexOf(oldData);
              try {
                await deleteNote(data[index].id);
              } catch {
                reject();
                return;
              }
              data.splice(index, 1);
              this.setState({ data }, () => resolve());
          }
          resolve()
          }, 1000)
        })
    }
}

export default withStyles(styles)(NoteTable);