import constants from '../API/constants';
import {Get, GetById, Add, Update, Delete} from '../API/api';

export async function getNotes() {
  return Get(constants.note);
}

export async function getNotesById(id){
  return GetById(constants.note, id);
}

export async function addNote(data){
  return Add(constants.note, data, true);
}

export async function updateNote(id, data){
  return Update(constants.note, id, data);
}

export async function deleteNote(id){
  return Delete(constants.note, id);
}