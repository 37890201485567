import React, { Component } from 'react';
import '../../custom.css';

export class Sibling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputData: {}
    }

    this.onChange = this.onChange.bind(this);
  }

  render() {
    let {language} = this.props;

    return (
      <div className="form-row slideDown" key={this.props.index}>
        <div className="form-group col-md-6">
          <label htmlFor="firstname">{language.firstName}</label>
          <input className="form-control" size="small" variant="outlined" id="FirstName" onChange={this.onChange} placeholder={language.firstName} required/>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="lastname">{language.lastName}</label>
          <input className="form-control" size="small" variant="outlined" id="LastName" onChange={this.onChange} placeholder={language.lastName} required/>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="personnummer">{language.ssn}</label>
          <input className="form-control" size="small" pattern="[0-9]{6}-.{4}" variant="outlined" id="Ssn" onChange={this.onChange} placeholder={language.ssnPlaceholder} required />   
        </div>
      </div>
    );
  }

  onChange(event){
    let {inputData} = this.state;
    let target = event.target;
    let type = target.type;

    switch(type){
      case 'text':
      case 'phone':
      case 'email':
        inputData[target.id] = target.value;
        break;
    }

    this.props.onChange(inputData, this.props.index);
    this.setState({inputData:inputData});
  }
}