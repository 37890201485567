import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {isoLangs} from '../Language';

const styles = theme => ({
});

const countries = Object.keys(isoLangs).map((key) => {
  return {label:`${isoLangs[key].name} (${isoLangs[key].nativeName})`, code:key};
});

export class CountrySelect extends Component {
  static displayName = CountrySelect.name;

  constructor(props){
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  render() {
    let value = this.props.value === undefined || this.props.value === null
      ? "svenska (svenska)"
      : this.props.value;

    let content = 
      <select className="selectpicker form-control" value={value} data-style="form-control" data-live-search="true" onChange={this.onChange} required>
        {countries.map((val, index) => {
          if(val.label !== undefined){
            return <option key={index} value={val.label}>{val.label}</option>
          }
        })}
      </select>

    return content;
  }

  onChange(event){
    this.props.onChange(event.target.value);
  }
}

export default withStyles(styles)(CountrySelect);