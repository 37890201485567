import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Grid, Table, TableRow, TableCell, TableBody, Tooltip, Card, IconButton, CardHeader, CardContent, Avatar } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DoneIcon from '@material-ui/icons/Done';
import TextField from '@material-ui/core/TextField';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const styles = theme => ({
  root: {
  },
  media: {
    height: 0
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: green[500],
  }
});

class SiblingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      data:this.props.data
    };

    this.openEditing = this.openEditing.bind(this);
    this.closeEditing = this.closeEditing.bind(this);
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.setState({data:this.props.data});
    }
  }

  createField(id, data) {
    return this.state.editing
      ? <TextField variant="outlined" size="small" id={id} defaultValue={data} onChange={this.onChange.bind(this)} />
      : data;
  }

  createDataCard() {
    const { classes } = this.props;
    const { data } = this.state;

    const actionButton = this.state.editing
      ? <Tooltip title="Klar">
        <IconButton aria-label="Klar" align='right' onClick={this.closeEditing}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
      : <Tooltip title="Ändra">
        <IconButton aria-label="Ändra" align='right' onClick={this.openEditing}>
          <EditIcon />
        </IconButton>
      </Tooltip>;

    return (
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar} />
          }
          title="Syskon"
          action={<div>
            <Tooltip title="Ta bort">
              <IconButton aria-label="ta bort" onClick={() => {this.props.onDelete(data)}}>
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
            {actionButton}
          </div>
          }
        />
        <CardContent>
          <Table>
            <TableBody>
              <TableRow key={0}>
                <TableCell component="th" scope="row">Förnamn:</TableCell>
                <TableCell align="right">{this.createField('firstName', data.firstName)}</TableCell>
              </TableRow>
              <TableRow key={1}>
                <TableCell component="th" scope="row">Efternamn:</TableCell>
                <TableCell align="right">{this.createField('lastName', data.lastName)}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="th" scope="row">Personnr:</TableCell>
                <TableCell align="right">{this.createField('ssn', data.ssn)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }

  createEmptyCard() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <CardContent>
          <Grid container justify="center" alignContent="center" alignItems="center">
            <Grid item xs="auto">
              <Tooltip title="Lägg till syskon">
                <IconButton aria-label="lägg till syskon" onClick={() => {this.props.onAdd(this.props.applicantId)}}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  render() {
    const { data } = this.props;
    let content = (data == null) ? this.createEmptyCard() : this.createDataCard();
    return (
      <div>
        {content}
      </div>
    );
  }

  openEditing() {
    this.setState({ editing: true });
  }

  closeEditing() {
    this.setState({ editing: false });
    this.props.onUpdate(this.state.data);
  }

  onChange(event) {
    let {data} = this.state;
    const target = event.target;
    data[target.id] = target.value;
    this.setState({data:data});
  }
}

export default withStyles(styles)(SiblingCard);