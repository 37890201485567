import React, { Component } from 'react';
import ParentCard from './ParentCard';
import { Grid } from '@material-ui/core';

class ParentView extends Component {

  render() {
    const { classes, data } = this.props;

    let addParentView = (data != null && data.length >= 2)
      ? ""
      : <Grid item key={-1} xs={6}>
          <ParentCard data={null} onAdd={this.props.onAdd} applicantId={this.props.applicantId} />
        </Grid>

    return (
      <Grid item xs={6}>
        <Grid container className={classes.root} spacing={1}>
          {data.map((parent, index) => {
            return (
              <Grid item key={index} xs={6}>
                <ParentCard data={parent} onDelete={this.props.onDelete} onUpdate={this.props.onUpdate} />
              </Grid>
            );
          })}
          {addParentView}
        </Grid>
      </Grid>
    );
  }
}

export default ParentView;