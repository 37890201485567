import constants from '../API/constants';
import {Get, GetById, Add, Update, Delete} from '../API/api';

export async function getSettingTypes() {
  return Get(constants.setting_type);
}

export async function getSettings() {
  return Get(constants.setting);
}

export async function getSettingById(id){
  return GetById(constants.setting, id);
}

export async function addSetting(data){
  return Add(constants.setting, data, true);
}

export async function updateSetting(id, data){
  return Update(constants.setting, id, data);
}

export async function deleteSetting(id){
  return Delete(constants.setting, id);
}

export async function isAdmin(){
  return Get(constants.admin);
}